var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-chip',[_c('v-icon',{attrs:{"small":""}},[_vm._v("search")]),_c('v-text-field',{staticClass:"mb-1",staticStyle:{"width":"300px"},attrs:{"placeholder":"Search","hide-details":"","rounded":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('ag-grid-vue',{staticStyle:{"width":"98%","height":"85vh"},attrs:{"groupDisplayType":"multipleColumns","animateRows":false,"rowGroupPanelShow":'always',"autoSizeStrategy":{
      type: 'fitGridWidth',
      defaultMinWidth: 125,
      columnLimits: [
        {
          colId: 'documentationStatus',
          minWidth: 120,
          width: 120,
        },
        {
          colId: 'fileNumber',
          minWidth: 180,
          width: 180,
        },
        {
          colId: 'vesselVoyage',
          minWidth: 200,
        },
        {
          colId: 'shipperName',
          minWidth: 200,
        },
        {
          colId: 'onBehalfShipperName',
          minWidth: 200,
        },
        {
          colId: 'consigneeName',
          minWidth: 200,
        },
      ],
    },"enableRangeSelection":true,"allowContextMenuWithControlKey":true,"getContextMenuItems":_vm.getContextMenuItems,"columnDefs":_vm.columns,"rowData":_vm.files,"suppressAggFuncInHeader":true,"defaultColDef":_vm.defaultColDef.def,"sideBar":_vm.sidebar,"statusBar":_vm.statusBar,"initialState":_vm.initialState,"autoGroupColumnDef":_vm.autoGroupColumnDef},on:{"state-updated":_vm.onStateUpdated,"grid-ready":_vm.onGridReady}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }