
<template>
    <div>
        <div v-if="item" class="text-center">
    <v-chip
v-if="!item.breakBulkShipment"
x-small
class="primaryText--text"
outlined
style="border: none;font-size: 11px"
><span>{{ item.containerQuantity }} Container(s)</span>
</v-chip>
<v-chip
v-else
x-small
class="primaryText--text"
:color="'teal'"
style="font-size: 11px"
><span>Breakbulk</span>
</v-chip>
</div>
<span v-else-if="value">{{ value }}</span>

    </div>


</template>
<script>
export default {
  data: () => ({
    item: null,
    value: null
  }),
  mounted() {
    this.item = this.params.shipment
    if(!this.item){
        this.value = this.params.value
    }
  },
  methods: {},
};
</script>